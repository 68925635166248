export const minPlayerInfoFragment = `
  fragment MinPlayerInfoFragment on Player {
      officialId
      firstName
      lastName
      profileUrl
      slug
}
  `

  export const fullPlayerStatsFragment = `
  fragment FullPlayerStatsFragment on PlayerStats {
      points
      scoringPoints
      faceoffPct
      shots
      shotPct
      shotsOnGoal
      shotsOnGoalPct
      twoPointShots
      twoPointShotPct
      twoPointShotsOnGoal
      twoPointShotsOnGoalPct
      savePct
      onePointGoals
      scoresAgainst
      saa
      gamesPlayed
      goals
      twoPointGoals
      assists
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      pimValue
      saves
      powerPlayGoals
      powerPlayShots
      shortHandedGoals
      shortHandedShots
      shortHandedGoalsAgainst
      powerPlayGoalsAgainst
      tof
      goalieWins
      goalieLosses
      goalieTies
      GAA
      twoPtGaa
      plusMinus
      foRecord
      shotTurnovers
      touches
      totalPasses
      unassistedGoals
      assistedGoals
}
`

export const fullPlayerGameStatsFragment = `
  fragment FullPlayerGameStatsFragment on PlayerStats {
      points
      onePointGoals
      twoPointGoals
      goals
      assists
      shots
      shotPct
      shotsOnGoal
      twoPointShots
      saves
      savePct
      GAA
      twoPtGaa
      scoresAgainst
      saa
      gamesPlayed
      groundBalls
      turnovers
      causedTurnovers
      faceoffsWon
      faceoffsLost
      faceoffs
      faceoffPct
      goalsAgainst
      twoPointGoalsAgainst
      numPenalties
      pim
      pimValue
      foRecord
      assistOpportunities
      totalPasses
      touches
}
`

export const playerAllTeamsFragment = `
  fragment PlayerAllTeamsFragment on Team {
      officialId
      location
      locationCode
      urlLogo
      league
      position
      positionName
      jerseyNum
      year
      fullName
}
`
