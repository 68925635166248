import React from 'react'
import { EventGQL } from '../../../../Api'
import { logEvent } from '../../../../Utils'
import {
  createDayTicketUrl,
  getPreviewUrl,
} from '../../../../Utils/EventHelpers/eventHelpers'
import { BoxscoreActionPrimary, BoxscoreActionSecondary } from '../styles'

type ActionProps = {
  event: EventGQL
  buttonText: string
}

export const BoxScoreAction: React.FC<React.PropsWithChildren<ActionProps>> = ({
  event,
  buttonText,
}) => {
  const gameUrl = `/games/${event.year}/${event.slugname}`

  const handleAction = () => {
    const params = {
      button: 'action_button',
      button_text: buttonText,
      section: 'game_ticker',
      event_id: event?.eventId,
      event_slug: event?.slugname
    }
    if(buttonText === 'Tickets'){
      logEvent('ticket_click', params)
      return
    }
    logEvent('boxscore_click', params)
  }

  return (
    <>
      {event.urlTicket && event.eventStatus === 0 && buttonText === 'Tickets' && (
        <BoxscoreActionPrimary
          href={createDayTicketUrl(event.urlTicket, 'Ticker', event.week)}
          onClick={() => handleAction()}
        >
          {buttonText}
        </BoxscoreActionPrimary>
      )}

      {event.eventStatus === 1 && buttonText === 'Watch' && (
        <BoxscoreActionPrimary
          href={event.urlStreaming}
          isLive={true}
          onClick={() => handleAction()}
        >
          {buttonText}
        </BoxscoreActionPrimary>
      )}

      {event.eventStatus === 0 &&
        event.awayTeam &&
        event.homeTeam &&
        buttonText === 'Preview' && (
          <BoxscoreActionSecondary
            href={getPreviewUrl(event?.slugname)}
            onClick={() => handleAction()}
          >
            {buttonText}
          </BoxscoreActionSecondary>
        )}

      {event.eventStatus >= 1 && buttonText === 'View Stats' && (
        <BoxscoreActionSecondary
          href={gameUrl}
          onClick={() => handleAction()}
        >
          {buttonText}
        </BoxscoreActionSecondary>
      )}
    </>
  )
}
